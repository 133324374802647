import { useState, useEffect } from "react";
import Date from "../../../Components/Date";
import "../styles/filter.sass";
import Select from "../../../Components/Select";
import { useLocation } from "react-router-dom";
import moment from "moment";
const XLSX = require("xlsx");

const initial = { search: "", date_de_candidature: "" };
const Filter = ({ filter: [data, setData], selected }) => {
  const [offresOptions, setOffresOptions] = useState([]);
  // eslint-disable-next-line
  const [dataExport, setDataExport] = useState([]);
  const apiURL = "https://api.cmconsulting-rh.com/candidatures/";
  const token = localStorage.getItem("token");
  const [show, setShow] = useState(false);
  let location = useLocation();

  const jobTitle = location?.state?.jobTitle;

  useEffect(() => {
    if (jobTitle) {
      console.log("Filter change by the root", jobTitle);
      setData((e) => ({
        ...e,
        offre: jobTitle,
      }));
    }
    window.history.replaceState({}, document.title);
  }, [jobTitle, setData]);

  const handleInputs = ({ target: { name, value } }) => {
    setData((e) => ({ ...e, [name]: value }));
  };

  const handleExport = () => {
    const wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(selected);
    XLSX.utils.book_append_sheet(wb, ws, `${moment().format("LL")}`);
    XLSX.writeFile(wb, `${moment().format("LL")}.xlsx`);
  };

  useEffect(() => {
    fetch(apiURL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setDataExport(response);
      });
    fetch("https://api.cmconsulting-rh.com/offres/")
      .then((res) => res.json())
      .then((response) => {
        setOffresOptions(response.map((e) => e.jobTitle));
      });
  }, [token]);

  // console.log();

  return (
    <div className="controler">
      <div className="actions">
        <div className="actions-section Actions-l">
          <div className="searchInput">
            <i className="fa-solid fa-magnifying-glass"></i>
            <input
              value={data.search}
              onChange={handleInputs}
              placeholder="Rechercher une candidature..."
              type="search"
              name="search"
            />
          </div>
          <button onClick={() => setShow((e) => !e)} id="showFilter">
            <i className="fa-solid fa-sliders"></i>
            &nbsp; Filtres avanc&eacute;s &nbsp;
            <i className="fa-solid fa-angle-down"></i>
          </button>
        </div>
        <div className="actions-section Actions-r">
          <button disabled={!selected.length} onClick={handleExport}>
            Exporter la sélection &nbsp;{" "}
            <i className="fa-solid fa-arrow-up-right-from-square"></i>
          </button>
        </div>
      </div>
      <div className={show ? "megaFilter act" : "megaFilter"}>
        <div className="flexContainer">
          <div className="filter-l">
            <Date
              handleInputs={handleInputs}
              title="Date de candidature"
              value={data.date_de_candidature}
              name="date_de_candidature"
              placeholder="De ..."
            />
            <Date
              handleInputs={handleInputs}
              title="à"
              placeholder="à ..."
              hideTitle
              value={data.date_de_candidature_a}
              name="date_de_candidature_a"
            />
            <Select
              placeholder="Filtrer par Métier"
              title="Offre d'emploi"
              handleInputs={handleInputs}
              value={data.offre}
              name="offre"
              options={offresOptions}
            />
            <RadioInput
              title={"Civilité"}
              name={"civilite"}
              value={data.civilite}
              handleInputs={handleInputs}
              options={["M.", "Mme.", "Mlle."]}
            />
            <RadioInput
              title={"Mobilité géographique"}
              name={"mobilite_Geographique"}
              value={data.mobilite_Geographique}
              handleInputs={handleInputs}
              options={["Internationale", "Nationale"]}
            />
            <Select
              placeholder="Filtrer par État"
              title="État"
              handleInputs={handleInputs}
              value={data.etat}
              name="etat"
              values={[
                "nouvelle",
                "en-cours",
                "preselectionnee",
                "validee",
                "refusee",
              ]}
              options={[
                "Nouvelle",
                "En cours",
                "Présélectionnée",
                "Validée",
                "Refusée",
              ]}
            />
            <Select
              placeholder="Filtrer par Mention"
              title="Mention"
              handleInputs={handleInputs}
              value={data.mention}
              name="mention"
              options={mentionOptions}
            />
            <Select
              placeholder="Filtrer par Pays"
              title="Pays"
              handleInputs={handleInputs}
              value={data.pays}
              name="pays"
              options={paysOptions}
            />
          </div>
          <div className="reset">
            <button onClick={() => setData(initial)}>
              Réinitialiser les filtres{" "}
              <i className="fa-solid fa-rotate-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// const etatOptions = ["Nouvelle", "En cours", "Traitée", "Supprimée"];
const mentionOptions = ["Faible", "Bien", "Excellent"];
const paysOptions = ["Algérie", "France", "Tunisie"];

const RadioInput = ({ name, value, options, handleInputs, title }) => (
  <div className="fields checkf">
    <label>{title}</label>
    <div>
      {options.map((e) => (
        <label key={e}>
          <input
            hidden
            onChange={handleInputs}
            checked={e === value}
            type="radio"
            name={name}
            value={e}
          />{" "}
          <span className="yellowCircle"></span>
          &nbsp; {e}{" "}
        </label>
      ))}
    </div>
  </div>
);

export default Filter;
