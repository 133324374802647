import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../../Components/Header";
import Select from "../../Components/Select";
import WYSIWYG from "./Components/WYSIWYG";
import "./styles/index.sass";
import Wilayas from "./Components/Wilayas.json";
import Departements from "./Components/Departements.json";
import Gouvernorats from "./Components/Gouvernorats.json";
import DatePicker from "../../Components/Date";
import toast, { Toaster } from "react-hot-toast";

const AjouterOffre = () => {
  let navigate = useNavigate();
  const url = "https://api.cmconsulting-rh.com/offres/";
  const [data, setData] = useState({
    email_destinataire: "",
    post: "",
    date_publication: "",
    date_expiration: "",
    pays: "",
  });
  const { id } = useParams();

  const submit = async (e) => {
    e.preventDefault();
    const notifySuccess = () =>
      toast.success("Offre d'emploi créée avec succès !");

    const notifyError = (msg) =>
      toast.error(`Une erreur est survenue : ${msg}`);

    const token = localStorage.getItem("token");

    const res = await fetch(`${url}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        title: data.post,
        jobTitle: data.post,
        jobDescription: data.jobDescription,
        requiredProfile: data.requiredProfile,
        autre: data.autre,
        email_destinataire: data.email_destinataire,
        nature: data.nature,
        type_contrat: data.type_contrat,
        pays: data.pays,
        wilaya: data.wilaya,
        publicationDate: data.date_publication,
        expirationDate: data.date_expiration,
        status: "publish",
      }),
    });

    if (res.status === 200) {
      notifySuccess();
      setTimeout(() => navigate("/GestionDesOffres/"), 2000);
    } else {
      const g = await res.json();
      console.log("Test error", g.message);
      notifyError(g.message);
    }
  };

  const handleInput = ({ target: { name, value } }) =>
    setData((pre) => ({ ...pre, [name]: value }));

  const minDate = new Date().toISOString().split("T")[0];

  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <>
      <Header />
      <Toaster />
      <h2>{(id ? "Modifier" : "Ajouter") + " une offre d'emploi"}</h2>
      <div className="container Ajouteoffre">
        <form onSubmit={(e) => submit(e)}>
          <div className="formEmploi">
            <div className="col col-1">
              <input
                value={data.post}
                onChange={handleInput}
                name="post"
                type="text"
                placeholder="Intitulé du poste*"
              />
              <DatePicker
                value={data.date_publication}
                name="date_publication"
                min={minDate}
                handleInputs={handleInput}
                placeholder={"Date de publication de l'offre*"}
              />
              <DatePicker
                value={data.date_expiration}
                disabled={data.date_publication === "" ? true : false}
                min={
                  data.date_publication
                    ? new Date(data.date_publication)
                        .toISOString()
                        .split("T")[0]
                    : ""
                }
                name="date_expiration"
                handleInputs={handleInput}
                placeholder={"Date d'expiration de l'offre*"}
              />
              <RadioInput
                name="nature"
                handleInput={handleInput}
                values={["Emploi", "Stage"]}
                title="Nature :"
                value={data.nature}
              />
              <RadioInput
                name="type_contrat"
                handleInput={handleInput}
                values={["Plein temps", "Mi-temps"]}
                title="Type de poste"
                value={data.type_contrat}
              />
              <Select
                placeholder={"Pays*"}
                name="pays"
                options={["Algérie", "France", "Tunisie"]}
                value={data.pays}
                handleInputs={handleInput}
              />
              <Select
                placeholder={"Wilaya*"}
                name="wilaya"
                options={
                  data.pays === "Algérie"
                    ? Wilayas
                    : data.pays === "France"
                    ? Departements
                    : data.pays === "Tunisie"
                    ? Gouvernorats
                    : []
                }
                value={data.wilaya}
                handleInputs={handleInput}
              />
              <input
                value={data.email_destinataire}
                onChange={handleInput}
                name="email_destinataire"
                type="email"
                placeholder="E-mail du destinataire*"
              />
            </div>
            <div className="col col-2">
              <h4 className="fieldTitle">Description du poste</h4>
              <WYSIWYG
                name="jobDescription"
                placeholder={"Description du poste*"}
                setData={setData}
              />
              <h4 className="fieldTitle">Profil recherché</h4>
              <WYSIWYG
                name="requiredProfile"
                setData={setData}
                placeholder={"Profil recherché*"}
              />
              <h4 className="fieldTitle">Autres</h4>

              <WYSIWYG
                setData={setData}
                name="autre"
                placeholder={"Autres..."}
              />
            </div>
          </div>
          <button className="downloadBtn">Ajouter l'offre d'emploi</button>
        </form>
      </div>
    </>
  );
};

export default AjouterOffre;

const RadioInput = ({ title, values, value, name, handleInput }) => {
  return (
    <div className="radioInput">
      <span>{title}</span>
      {values.map((e) => (
        <label key={e}>
          <input
            hidden
            onChange={handleInput}
            checked={e === value}
            value={e}
            name={name}
            type="radio"
          />
          <span className="yellowCircle"></span>
          {" " + e}
        </label>
      ))}
    </div>
  );
};
