import { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import axios from "axios";

ChartJS.register(BarElement, CategoryScale, LinearScale);
const baseUrl = "https://api.cmconsulting-rh.com/candidatures/stats/";
const token = localStorage.getItem("token");

const fetchStatsNew = (selectedValue) =>
  axios.post(
    baseUrl,
    {
      poste: selectedValue.jobTitle,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

const BarChart = ({ selectedValue }) => {
  const [chart, setChart] = useState([]);
  useEffect(() => {
    var tableau = [];
    fetchStatsNew(selectedValue).then(function (value) {
      console.log("select option:", selectedValue.jobTitle, " response :", {
        value,
      });
      var tableau_2 = Object.values(value.data);

      for (var i = 1; i <= tableau_2.length; i++)
        tableau.push(tableau_2[i - 1]);

      setChart(tableau);
    });
  }, [selectedValue]);

  const data = {
    labels: [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ],
    datasets: [
      {
        label: "Candidature(s)",
        data: chart,
        backgroundColor: ["#00358c"],
        borderWidth: 1,
      },
    ],
  };

  var options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        suggestedMax: 50,
      },
    },
    legend: {
      labels: {
        fontSize: 26,
      },
    },
  };

  return (
    <div className="container">
      <div className="chart-container">
        <span>
          {selectedValue.jobTitle !== "all"
            ? `Nombre de candidatures reçues pour le poste de ${selectedValue.jobTitle}`
            : "Nombre total de candidatures reçues par mois"}
        </span>
        <div className="chart-wrapper-t">
          <Bar height={400} data={data} options={options} />
        </div>
      </div>
    </div>
  );
};

export default BarChart;
