import { useEffect, useState } from "react";
import "../../../styles/table.sass";
import DataTable, { createTheme } from "react-data-table-component";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import EditerOffre from "../../EditerOffre";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Pagination from "../../GestionDesCandidatures/Components/Pagination";

const Table = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [offrePerPage] = useState(5);
  let navigate = useNavigate();

  const apiURL = "https://api.cmconsulting-rh.com/offres/";
  const token = localStorage.getItem("token");

  const route = useLocation();

  console.log(route);

  useEffect(() => {
    console.log("refetch");
    setIsLoading(true);
    fetch(apiURL)
      .then((response) => response.json())
      .then((responseData) => {
        responseData.sort((a, b) => a.id > b.id).reverse();
        setResponse(responseData);
        setIsLoading(false);
      });
  }, [route]);

  // Get current offres
  const indexLastCand = currentPage * offrePerPage;
  const indexFirstCand = indexLastCand - offrePerPage;

  // Table Data without "trash" Status
  const allValid = response.filter(
    (statusFilter) => statusFilter.status !== "trash"
  );

  const toDisplay = allValid.slice(indexFirstCand, indexLastCand);

  // Change page
  const paginate = (e, pageNumber) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, []);

  const navigateCand = (e, jobTitle) => {
    e.preventDefault();
    navigate("/", { state: { jobTitle: jobTitle } });
  };

  const columns = [
    {
      id: 1,
      name: "Poste",
      selector: (row) => row.jobTitle,
      sortable: true,
      reorder: true,
      center: true,
      allowOverflow: false,
    },
    {
      id: 2,
      name: "Validité",
      selector: (row) => (
        <div>
          Du {row.publicationDate} <br />
          Au {row.expirationDate}
        </div>
      ),
      sortable: true,
      reorder: true,
      center: true,
      allowOverflow: true,
    },
    {
      id: 3,
      name: "E-mail du destinataire",
      selector: (row) => row.email_destinataire,
      sortable: true,
      reorder: true,
      center: true,
      allowOverflow: true,
      className: "emailField",
    },
    {
      id: 4,
      name: "Type de poste",
      selector: (row) => row.nature,
      sortable: true,
      reorder: true,
      center: true,
      allowOverflow: true,
    },
    {
      id: 5,
      name: "Wilaya",
      selector: (row) => row.wilaya,
      sortable: true,
      reorder: true,
      center: true,
      allowOverflow: true,
    },
    {
      id: 6,
      name: "Candidatures",
      selector: (row) => (
        <a
          className="blue-txt"
          href={"!#"}
          onClick={(e) => {
            navigateCand(e, row.jobTitle);
          }}
        >
          <i className="fa-solid fa-list-ul"></i>Liste des candidatures{" "}
        </a>
      ),
      sortable: false,
      reorder: false,
      center: true,
      allowOverflow: true,
    },
    {
      id: 7,
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
      reorder: true,
      center: true,
      allowOverflow: true,
      omit: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="actions-btn">
          <Link to={`/GestionDesOffres/preview/${row.id}`}>
            <i className="fa-solid fa-eye"></i>
          </Link>
          <Link
            to={`/GestionDesOffres/EditerOffre/${row.id}`}
            onClick={(e) => EditerOffre(row.id, e)}
          >
            <i className="fa-solid fa-pen"></i>
          </Link>
          <Link to="#!" onClick={(e) => popDelete(row.id, e)}>
            <i className="fa-solid fa-trash-can"></i>
          </Link>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "150px",
    },
  ];

  const deleteOffre = (id, e) => {
    const statusUpdate = {
      ...response.find((e) => e.id === id),
      status: "trash",
    };

    e.preventDefault();
    fetch(`${apiURL}${id}/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(statusUpdate),
    }).then((response) => {
      return response.json();
    });
  };

  const popDelete = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="deleteWindow">
            <h3>Supprimer l'offre d'emploi ?</h3>
            <p>
              Etes-vous sûr de vouloir supprimer l'offre d'emploi ? Cette action
              est definitive. Les candidatures reliées à l'offre d'emploi
              restent valables.
            </p>
            <div className="actions">
              <a href="#!" className="cancel" onClick={onClose}>
                Annuler
              </a>
              <a
                href="#!"
                className="delete"
                onClick={(e) => {
                  deleteOffre(id, e);
                  onClose();
                  window.location.reload();
                }}
              >
                <i className="fa-solid fa-trash-can"></i>
                Supprimer
              </a>
            </div>
          </div>
        );
      },
    });
  };

  createTheme(
    "cmc",
    {
      background: {
        default: "#fff",
      },
      text: "#00358c",
    },
    "light"
  );

  return (
    <div className="tableContainer">
      <div className="mainTable">
        <DataTable
          theme="cmc"
          columns={columns}
          progressPending={isLoading}
          data={toDisplay}
          defaultSortFieldId={7}
          defaultSortAsc={false}
          selectableRows
          onSelectedRowsChange={handleRowChange}
          noDataComponent="Votre requête n’a retourné aucun résultat"
        />
        <Pagination
          candPerPage={offrePerPage}
          totalCand={allValid.length}
          paginate={paginate}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};

export function handleRowChange({ selectedRows }) {
  console.log(selectedRows);
}

export default Table;
