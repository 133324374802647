import cvIcon from "../images/resume-cv.svg";

export const filterFun = (filter, response) => {
  const startData = new Date(filter?.date_de_candidature).getTime();
  const endData = new Date(filter?.date_de_candidature_a).getTime();
  const filteredRes = response.filter((e) => {
    if (filter.date_de_candidature && new Date(e.created).getTime() > endData) {
      return false;
    }
    if (
      filter.date_de_candidature_a &&
      new Date(e.created).getTime() < startData
    ) {
      return false;
    }

    if (
      filter.civilite &&
      e.designation.toLowerCase() !== filter.civilite.toLowerCase()
    )
      return false;

    if (
      filter.mobilite_Geographique &&
      !e.mobilite_geographique
        .toLowerCase()
        .includes(filter.mobilite_Geographique.toLowerCase())
    )
      return false;

    if (filter.offre && e.poste.toLowerCase() !== filter.offre.toLowerCase())
      return false;
    if (filter.etat && e.status.toLowerCase() !== filter.etat.toLowerCase())
      return false;
    if (
      filter.mention &&
      e.mention.toLowerCase() !== filter.mention.toLowerCase()
    )
      return false;
    if (filter.pays && e.pays.toLowerCase() !== filter.pays.toLowerCase())
      return false;

    if (
      filter.search.length > 3 &&
      ![e.email, e.nom + " " + e.prenom].find((e) =>
        e.toLowerCase().includes(filter.search.toLowerCase())
      )
    )
      return false;

    return true;
  });
  return filteredRes;
};

export function getCandidatures(response) {
  const displayData = response.map(function (candidature) {
    candidature.cv_html = (
      <div>
        <a href={`${candidature.cv}`} target="_blank" rel="noreferrer">
          <img alt="CV" className="cv_icon" src={cvIcon}></img>
        </a>
      </div>
    );

    if (candidature.mention === null) candidature.mention = "Aucune";
    return candidature;
  });
  return displayData;
}
