import axios from "axios";

const token = localStorage.getItem("token");

export default axios.create({
  baseURL: "https://api.cmconsulting-rh.com",
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});
